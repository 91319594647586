<template>
  <div>
    <v-card class="pp-card empty-result" v-if="results && isResultEmpty">
      <div class="text-center pa-8">
        <p class="ma-0">Record not found</p>
      </div>
    </v-card>
    <div class="search-result-list" v-if="results && !isResultEmpty">
      <user-details-card
        @click="alertParent"
        :key="result.id"
        :user="result"
        v-for="result in results"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import UserDetailsCard from '@/components/cards/UserDetailsCard'
export default {
  name: 'SSearchBarResultsList',
  components: {
    'user-details-card': UserDetailsCard,
  },
  props: {
    results: {
      type: Array,
      required: false,
      default: null,
    },
  },
  computed: {
    isResultEmpty () {
      return _.isEmpty(this.results)
    },
  },
  methods: {
    alertParent (entity) {
      this.$emit('click', entity)
    },
  },
}
</script>

<style lang="scss" scoped>
.search-result-list,
.empty-result {
  position: absolute;
  z-index: 98;
  width: 100%;
  margin-top: 4px;
  box-shadow: 0px 4px 8px rgba(185, 188, 201, 0.16) !important;
  border-radius: 12px;
}

.empty-result {
  border: 1px solid #eeeeee;
  color: #999999;
}

.search-result-list {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(185, 188, 201, 0.16) !important;
  list-style: none;
  max-height: 400px;
  overflow-y: scroll;

  .search-result-list__header {
    text-transform: uppercase;
    display: block;
    color: #aaaaaa;
    font-size: 12px;
    font-weight: 700;
  }
}

.pp-search-bar-add-new-delimiter {
  margin-left: 0.7em;
  margin-right: 0.7em;
  top: 0;
  height: 1px;
  background: #cccccc;
  left: 12px;
  right: 12px;
}

.pp-search-bar-add-new-container {
  width: 100%;
  text-align: center;
  line-height: 5em;
  &:hover,
  .pp-search-bar-add-new-icon:hover,
  .pp-search-bar-add-new-label:hover {
    cursor: pointer;
    background-color: #cccccc;
  }
}

.pp-search-bar-add-new-container:before {
  content: '';
  position: absolute;
  top: 0;
  height: 1px;
  background: #cccccc;
  left: 12px;
  right: 12px;
}

.pp-search-bar-add-new-icon {
  position: relative;
  top: 0.2em;

  ::v-deep {
    svg {
      width: 1.2em;

      path {
        fill: #0099ff;
      }
    }
  }
}

.pp-search-bar-add-new-label {
  color: #0099ff;
  font-weight: bold;
}
</style>
